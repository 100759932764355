import React from "react";
import { Link as GatsbyLink } from "gatsby";

const Link = (props) => {
  if (props.to) {
    return <GatsbyLink {...props} />;
  }

  if (props.link.link_type === "Document") {
    return <GatsbyLink to={props.link.url}>{props.link_text}</GatsbyLink>;
  }

  return (
    <a href={props.url || props.href} target={props.target}>
      {props.link_text || props.children}
    </a>
  );
};

export default Link;
