import React from "react";
import { Flex } from "@chakra-ui/core";

const Section = ({ dark, children, ...rest }) => {
  // #01466e
  //   bgImage: "linear-gradient(0deg, #1D2A40, #253655)",
  const sectionProps = dark
    ? {
        // bg: "teal.200",

        bgImage:
          "url(https://images.unsplash.com/photo-1528460033278-a6ba57020470?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1575&q=80)",
        bgSize: "cover",
        bgPos: "bottom",
        // bg: "blue.900",
        color: "black",
      }
    : {
        bg: "transparent",
        color: "gray.900",
      };

  return (
    <Flex py={[8, 8, 16]} px={[6, 6, 8, 0]} {...sectionProps} {...rest}>
      {children}
    </Flex>
  );
};

export default Section;
