import React from "react";
import { Flex } from "@chakra-ui/core";
import styled from "@emotion/styled";

const sizes = {
  'sm': ["auto", "720px", "720px", "720px"],
  'md': ["auto", "900px", "900px", "900px"]
}

const Wrapper = (props) => (
  <Flex
    maxW={props.narrow ? sizes.sm : sizes.md}
    flex="1"
    m="auto"
    flexDirection="column"
    {...props}
  />
);

export default Wrapper;
