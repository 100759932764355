import React, { Component } from "react";

import { useStaticQuery, graphql } from "gatsby";

import { RichText } from "prismic-reactjs";
import { Flex, Box, Grid, Text } from "@chakra-ui/core";
import Section from "@components/Section";
import SectionTitle from "@components/SectionTitle";
import Wrapper from "@components/Wrapper";

import linkResolver from "@src/linkResolver";
import htmlSerializer from "@src/gatsby/htmlSerializer";

const Wave = () => {
  return (
    <Box
      position="absolute"
      width="100%"
      height="150px"
      transform="translateY(-100%)"
      fill="teal.700"
      left={0}
    >
      <Box
        as="svg"
        width="100%"
        height="100%"
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
      >
        <path
          fill="teal.700"
          as="path"
          d="M0.00,49.98 C137.69,109.03 346.50,-15.28 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          style={{ stroke: "none", fill: "#1f364a" }}
        ></path>
      </Box>
    </Box>
  );
};

const Footer = () => {
  const { prismicHomepage } = useStaticQuery(graphql`
    query FooterQuery {
      prismicHomepage {
        data {
          footer {
            raw
          }
          #   navigation {
          #     link {
          #       link_type
          #       url
          #       target
          #     }
          #     link_text
          #   }
        }
      }
    }
  `);

  return (
    <Section dark bg="teal.700" color="white">
      <Wave />
      <Wrapper flex="1">
        <Flex flexDirection={["column-reverse", "column-reverse", "row"]}>
          <Box flex="1" width="100%" pr={4}>
            <SectionTitle size="md" textAlign="left" mb={4}>
              Mottagning på Södermalm
            </SectionTitle>
            <Flex flexDirection="column">
              <RichText
                htmlSerializer={htmlSerializer}
                render={prismicHomepage.data.footer.raw}
                linkResolver={linkResolver}
              />
            </Flex>
          </Box>

          <Flex
            width="100%"
            alignItems="center"
            justifyContent="center"
            flex="1"
            // display={["none", "none", "grid"]}
          >
            {/* <Text textAlign="center">Karta</Text> */}
            <iframe
              src="https://widget.reco.se/v2/widget/4053127?mode=HORIZONTAL_QUOTE&inverted=false&border=false"
              height="225"
              // style="width:100%;border:0;display:block;overflow: hidden;"
              data-reactroot=""
            />
          </Flex>
        </Flex>
      </Wrapper>
    </Section>
  );
};

export default Footer;
