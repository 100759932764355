import styled from "@emotion/styled";
import React from "react";
import { Flex, Box, List, ListItem, Button } from "@chakra-ui/core";

import Link from "@components/Link";

export const StyledMenu = styled.nav`
  transform: ${(props) => (props.open ? `translateX(0)` : `translateX(-100%)`)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${(props) => {
    return props.theme.colors.blue["900"];
  }};
  color: white;
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 9999;

  a {
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s linear;

    &:hover {
      color: grey;
    }
  }
`;

const Menu = ({ links, open }) => {
  return (
    <StyledMenu open={open}>
      <Flex fontSize="2xl" mb={4}>
        <Link to="/">KSQ</Link>
      </Flex>
      <List listStyleType="none" flexDir="column" display="flex">
        {links.map((link) => (
          <ListItem
            key={`nav-${link.link.url}`}
            textTransform="uppercase"
            letterSpacing="1px"
            my={0}
            mt={1}
          >
            <Link {...link} />
          </ListItem>
        ))}
      </List>
    </StyledMenu>
  );
};
export default Menu;
