const { RichText } = require("prismic-dom");
const { Elements } = RichText;
const { Text } = require("@chakra-ui/core");

const React = require("react");
// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

exports.sectionSerializer = (type, element, content, children, key) => {
  console.log('hello')
  let props = {};
  console.log(type);  
  switch (type) {
    
    case Elements.paragraph:
      props = {
        as: "p",
        mb: 4,
        fontSize: "md",
      };
      return React.createElement(
        Text,
        propsWithUniqueKey(props, key),
        children
      );
    default:
  }
};

const htmlSerializer = (type, element, content, children, key) => {
  let props = {};
  switch (type) {
    case Elements.paragraph:
      props = {
        as: "p",
        mb: 4,
        fontSize: "sm",
      };
      return React.createElement(
        Text,
        propsWithUniqueKey(props, key),
        children
      );
    default:
      return null;
  }
};

module.exports = htmlSerializer;
