import React, { useState, useEffect, useCallback } from "react";
import Wrapper from "@components/Wrapper";
import { useStaticQuery, graphql } from "gatsby";

import styled from "@emotion/styled";

import {
  Flex,
  Box,
  List,
  ListItem,
  Button,
  Text,
  Link as StyledLink,
} from "@chakra-ui/core";

import Link from "@components/Link";
import MobileMenu from "@components/MobileMenu";

import throttle from "lodash/throttle";

const StyledBurger = styled.button`
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: white;
  width: 2rem;
  height: 1.5rem;
  background: transparent;

  cursor: pointer;
  padding: 0;

  border: 2px solid currentColor;
  border-color: ${({ open }) => (open ? "transparent" : "currentColor")};
  padding: 2px 4px;
  border-radius: 5px;
  z-index: 10000;

  &:focus {
    outline: none;
  }

  div:nth-of-type(1) {
    transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
  }

  div:nth-of-type(2) {
    opacity: ${({ open }) => (open ? "0" : "1")};
  }

  div:nth-of-type(3) {
    transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
  }

  div {
    width: 100%;
    // min-width: 29px;
    height: 2px;
    background: currentColor;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    position: relative;
    transform-origin: 3px;
  }
`;

const Burger = (props) => {
  return (
    <StyledBurger {...props}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

const useScrollThingy = () => {
  const [isAtTopOfDocument, setIsAtTopOfDocument] = useState(
    typeof window !== "undefined" && window.scrollY > 20
  );

  const throttledUpdate = useCallback(
    throttle(() => {
      setIsAtTopOfDocument(window.scrollY > 20);
    }, 500),
    []
  );

  useEffect(throttledUpdate, []);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    window.addEventListener("scroll", throttledUpdate);

    return () => {
      window.removeEventListener("scroll", throttledUpdate);
    };
  }, []);

  return isAtTopOfDocument;
};

const Navigation = ({ links, open, setOpen }) => {
  return (
    <>
      <Box display={["inherit", "inherit", "none", "none"]}>
        <Burger
          open={open}
          onClick={() => {
            setOpen(!open);
          }}
        />
      </Box>

      <List
        listStyleType="none"
        flexDir="row"
        fontWeight="500"
        display="flex"
        alignItems="center"
        display={["none", "none", "inherit"]}
      >
        {links.map((link) => (
          <ListItem
            key={`nav-${link.link.url}`}
            textTransform="uppercase"
            letterSpacing="0.5px"
            fontSize="2xs"
            mx={2}
            my={0}
            mt={1}
          >
            <Link {...link} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

const Header = ({ useBackgroundColor }) => {
  const isAtTop = useScrollThingy();
  const [open, setOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      prismicHomepage {
        data {
          footer {
            html
          }
          navigation {
            link {
              link_type
              url
              target
            }
            link_text
          }
        }
      }
    }
  `);

  const opaqueHeader = useBackgroundColor || isAtTop;

  return (
    <>
      <MobileMenu
        links={data.prismicHomepage.data.navigation}
        open={open}
        setOpen={setOpen}
      />
      <Box
        as="header"
        position="fixed"
        width="100%"
        py={2}
        px={4}
        bg={opaqueHeader ? "teal.700" : "transparent"}
        color={opaqueHeader ? "white" : "gray.900"}
        alignItems="center"
        m={"auto"}
        zIndex="9999"
        transition="background .25s"
      >
        <Wrapper>
          <Flex>
            <Flex alignItems="center">
              <Box
                color={["white", "white", "inherit", "inherit"]}
                fontWeight="bold"
                display={open ? "none" : "inherit"}
                fontSize="xl"
              >
                <Link to="/" color="inherit">
                  KSQ
                </Link>
                <Flex
                  flexDir="column"
                  borderLeft={
                    opaqueHeader
                      ? "2px solid currentColor"
                      : "1px solid currentColor"
                  }
                  pl={2}
                  ml={2}
                  opacity=".7"
                >
                  <Text as="span" fontSize="2xs">
                    Kerstin Sandqvist
                  </Text>
                  <Text as="span" fontSize="2xs">
                    Terapi & Utbildning
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex
              ml={"auto"}
              fontWeight="bold"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Navigation
                links={data.prismicHomepage.data.navigation}
                open={open}
                setOpen={setOpen}
              />
            </Flex>
          </Flex>
        </Wrapper>
      </Box>
    </>
  );
};

export default Header;
