/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import { Box, Flex } from "@chakra-ui/core";

import Footer from "@components/Footer";
import SEO from "@components/SEO";
import Header from "@components/Header";
import { Helmet } from "react-helmet";

const PureLayout = ({ children, data, customSEO, page }) => {
  return (
    <Flex flexDirection="column" minHeight="100vh">
      <Header useBackgroundColor={page} />
      {!customSEO && <SEO />}

      <Box pt={page ? "6rem" : 0} flex="1">
        {children}
      </Box>
      <Footer>
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicHomepage.data.footer.html,
          }}
        />
      </Footer>
    </Flex>
  );
};

class Layout extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            prismicHomepage {
              data {
                footer {
                  html
                }
                navigation {
                  link {
                    link_type
                    url
                    target
                  }
                  link_text
                }
              }
            }
          }
        `}
        render={(data) => (
          <PureLayout {...this.props} data={data}>
            {this.props.children}
          </PureLayout>
        )}
      />
    );
  }
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};

PureLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  data: PropTypes.object.isRequired,
  customSEO: PropTypes.bool,
};

PureLayout.defaultProps = {
  customSEO: false,
};
